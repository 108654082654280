import { Outlet } from 'react-router-dom';

import { AuthRouteObject } from './AuthRoute';
import { ComingSoon } from '@/components/ComingSoon';
import MainLayout from '@/components/Layout/MainLayout';
import ProjectLayout from '@/components/Layout/ProjectLayout';
import { Bidding, ConstructionManuals, LoginPage, ShiftTemplates } from '@/pages';
import NotFound from '@/pages/404';
import CompanyEmployee from '@/pages/CompanyEmployee';
import CreateProjectPage from '@/pages/CreateProjectPage';
import { HomePage } from '@/pages/HomePage';
import Organization from '@/pages/Organization';
import ProjectSettingPage from '@/pages/ProjectSettingPage';
import ProjectsPage from '@/pages/ProjectsPage';
import PublicPage from '@/pages/PublicPage';
import TeamManagePage from '@/pages/TeamManagePage';
import { TimelineSection as Timekeeping } from '@/pages/Timekeeping';

type MetaMenu = {
  name?: string;
  icon?: React.ReactNode;
};

export type MetaMenuAuthRouteObject = AuthRouteObject<MetaMenu>;

export const projectRouters: MetaMenuAuthRouteObject[] = [
  {
    element: <ProjectsPage />,
    name: 'Project List',
    auth: ['DuAn.View'],
    index: true,
  },
  {
    element: <ProjectLayout />,
    children: [
      {
        element: <ComingSoon />,
        path: '/projects/dashboard',
      },
      {
        element: <Bidding />,
        path: '/projects/bidding',
      },
      {
        element: <ComingSoon />,
        path: '/projects/contract-bid-kpis',
      },
      {
        element: <ComingSoon />,
        path: '/projects/prepare/cost-estimate',
      },
      {
        element: <ComingSoon />,
        path: '/projects/prepare/construction',
      },
      {
        element: <PublicPage />,
        path: '/projects/construction/init',
      },
      {
        element: <ComingSoon />,
        path: '/projects/construction/weekly-assignment',
      },
      {
        element: <ConstructionManuals />,
        path: '/projects/construction/manuals',
      },
      {
        element: <ComingSoon />,
        path: '/projects/construction/diary',
      },
      {
        element: <ComingSoon />,
        path: '/projects/construction/labor-safety-diary',
      },
      {
        element: <ComingSoon />,
        path: '/projects/construction/enviromental-hygiene-diary',
      },
      {
        element: <ComingSoon />,
        path: '/projects/materials-log',
      },
      {
        element: <ComingSoon />,
        path: '/projects/machinery-log',
      },
      {
        element: <ComingSoon />,
        name: 'Main material',
        path: '/projects/main-material',
      },
      {
        element: <ComingSoon />,
        name: 'Auxiliary material',
        path: '/projects/auxiliary-material',
      },
      {
        element: <ComingSoon />,
        name: 'Machinery',
        path: '/projects/machinery',
      },
      {
        element: <ComingSoon />,
        name: 'Transfer-material',
        path: '/projects/transfer-material',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/depot',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/subcontractors/subcontract',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/subcontractors/pay-the-subcontractor-12',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/subcontractors/pay-the-subcontractor-17',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/subcontractors/aggregate-costs',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/overtime',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/transfer',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/statistic-bonus',
      },
      {
        element: <TeamManagePage />,
        name: 'Team manage',
        path: '/projects/employees/team-manage',
        auth: ['QuanLyToDoi.View'],
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/union-welfare-funds/fee',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/union-welfare-funds/expenses',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/union-welfare-funds/bonus',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/union-welfare-funds/recurring-travel-expenses',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/make-an-advance-plan-12th',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/20th-payment-plan',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/make-an-advance-plan-27th',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/5th-payment-plan',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/aggregate-materials',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/track-cash-flow',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/bookkeeping',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/input-invoices',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/output-invoices',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/invoices-difference',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/invoice-x',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/input-invoices-and-x',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/compare-norms',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/supplier-investor-debt',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/profit-and-loss-statement',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/develoment-chart',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/Other-cost-charts',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/management-accounting/profit-chart',
      },
      {
        element: <Outlet />,
        name: 'Project management',
        path: '/projects/project-management',
        children: [
          {
            element: <ComingSoon />,
            path: '/projects/project-management/documents',
          },
          {
            element: <ComingSoon />,
            path: '/projects/project-management/contruction-expenses',
          },
        ],
      },
      {
        element: <Outlet />,
        name: 'KPI - Salary',
        path: '/projects/kpi-salary',
        children: [
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/kpis-of-departments',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/salary-of-departments',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/1st-salary-advance',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/1st-salary-payment',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/2nd-salary-advance',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/2nd-salary-payment',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/year-end-bonus',
          },
        ],
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/project-management-suppliers',
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/supervision-consultants-suppliers',
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/investors-project-management',
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/investors-supervision-consultant',
      },
      {
        element: <ComingSoon />,
        path: '/projects/kpi-salary/Other',
      },
      {
        element: <ProjectSettingPage />,
        path: '/projects/project-settings',
        auth: ['CaiDat.ThongTinChung.View', 'CaiDat.ThanhVien.View'],
      },
      {
        element: <Outlet />,
        name: 'Employees',
        path: '/projects/employees',
        children: [
          {
            element: <Timekeeping />,
            name: 'Check-in time',
            path: '/projects/employees/check-in-time',
            auth: ['ChamCong.View'],
          },
        ],
      },
    ],
  },
];

export const routers: MetaMenuAuthRouteObject[] = [
  {
    element: <MainLayout />,
    path: '/',
    name: 'Main',
    children: [
      {
        element: <HomePage />,
        name: 'Home',
        index: true,
      },
      {
        element: <Organization />,
        name: 'Organization',
        path: '/organization',
      },
      {
        element: <CreateProjectPage />,
        name: 'Create Project',
        path: '/create-project',
        auth: ['DuAn.Create'],
      },
      {
        element: <Outlet />,
        name: 'Projects',
        path: '/projects',
        children: projectRouters,
      },
      {
        element: <ShiftTemplates />,
        name: 'Shift Templates',
        path: '/shift-templates',
        auth: ['CaLamViec.View'],
      },
      {
        element: <Outlet />,
        name: 'Employee',
        path: '/employee',
        children: [
          {
            element: <CompanyEmployee />,
            name: 'Company employee',
            path: '/employee/company-employee',
            auth: ['CongTy.NhanSu.View'],
          },
          {
            element: <ComingSoon />,
            name: 'Company group',
            path: '/employee/company-group',
            auth: ['CongTy.PhongBan.View'],
          },
        ],
      },
    ],
  },
  {
    element: <LoginPage />,
    name: 'Login',
    path: '/login',
  },
  { path: '*', element: <NotFound /> },
];
