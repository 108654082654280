import dayjs from 'dayjs';
import { Observable } from 'rxjs';

import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { checkInUrl } = getEnvVars();

export interface ShiftResponse {
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  label?: string;
}

export interface TeamsResponse {
  id: number;
  operator_Id: string;
  name: string;
  status: number;
  leader_Id: string;
  shifts: ShiftResponse[];
}

export interface CheckInPayload {
  team_id: number;
  working_day: dayjs.Dayjs;
}

export interface ApprovedHoursWorkingPayload {
  working_day: dayjs.Dayjs;
  face_Identity_Id: string;
  day_Hours: dayjs.Dayjs;
  approved_Day_Hours: dayjs.Dayjs;
  approved_Note: string;
  team_id: number;
}

export interface CheckInMealPayload {
  working_Day: dayjs.Dayjs;
  mealList: {
    face_Identity_Id: string;
    information: string;
  }[];
}

export interface CheckInResponse {
  date_Key: number;
  face_Identity_Id: string;
  employeeId?: number;
  employeeCode?: string;
  employee_Name?: string;
  day_Hours?: string;
  approved_Day_Hours?: string | null;
  approved_Note?: string | null;
  checkin_Note?: string | null;
  shift_Hours?: string | null;
  approved_Shift_Hours?: { [key: string]: number } | null;
  totalApprovedMainShift: number;
  totalApprovedOTShift: number;
  approvedExtra?: string | null;
}

export interface ApproveAttendancePayload {
  working_Day: string;
  face_Identity_Id: string;
  day_Hours: string;
  approved_Day_Hours: string;
  approved_Note?: string;
  team_id?: number;
  approvedExtra?: string;
}

class FaceCheckController {
  public Get = {
    fetchTeamsOfOperator: (operatorId: number, options?: RequestOptions) => {
      return HttpClient.get(`${checkInUrl}/api/checkin/operator/${operatorId}/teams`, options);
    },
    fetchTimeKeepingOfTeam: (params: CheckInPayload, options?: RequestOptions) => {
      const { team_id, working_day } = params;
      const wkd = working_day.format('YYYY-MM-DD');
      return HttpClient.get(`${checkInUrl}/api/checkin/team/${team_id}?working_day=${wkd}`, options);
    },
    fetchTimeKeepingOfTeamV2: (params: CheckInPayload, options?: RequestOptions) => {
      const { team_id, working_day } = params;
      const wkd = working_day.format('YYYY-MM-DD');
      return HttpClient.get(`${checkInUrl}/api/checkin/v2/team/${team_id}?working_day=${wkd}`, options);
    },
    exportExcel: (params: any, options?: RequestOptions) => {
      const { companyId, monthNumber } = params;
      return HttpClient.get(`${checkInUrl}/api/CheckIn/company/${companyId}/report/month/${monthNumber}`, options);
    },
    fetchCheckInPhoto: (checkInId: number, options?: RequestOptions) => {
      const customOptions: RequestOptions = {
        ...options,
        responseType: 'blob',
      };
      return HttpClient.get(`${checkInUrl}/api/checkin/checkin/${checkInId}/image`, customOptions);
    },
    getDailyCheckInReportByTeamId: (teamId: number, options?: RequestOptions): Observable<CheckInResponse[]> => {
      return HttpClient.get(`${checkInUrl}/api/CheckIn/team/${teamId}/report/daily`, options);
    },
  };

  public Post = {
    approvedHoursWorking: (input: ApprovedHoursWorkingPayload, options?: RequestOptions) => {
      const { working_day, day_Hours, approved_Day_Hours, approved_Note, face_Identity_Id, team_id } = input;
      const data = {
        face_Identity_Id,
        working_Day: working_day.format('YYYY-MM-DD'),
        day_Hours: day_Hours.format('HH:mm:ss'),
        approved_Day_Hours: approved_Day_Hours.format('HH:mm:ss'),
        approved_Note,
        team_id,
      };
      return HttpClient.post(`${checkInUrl}/api/checkin/attendance`, data, options);
    },
    checkInMeal: (input: CheckInMealPayload, options?: RequestOptions) => {
      const data = {
        working_Day: input.working_Day.format('YYYY-MM-DD'),
        mealList: input.mealList,
      };
      return HttpClient.post(`${checkInUrl}/api/checkin/meals`, data, options);
    },
    approveAttendanceByDay: (input: ApproveAttendancePayload[], options?: RequestOptions) => {
      return HttpClient.post(`${checkInUrl}/api/CheckIn/attendance/month`, input, options);
    },
  };
}

export const FaceCheckService = new FaceCheckController();
