import { notification } from 'antd';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

import { MenuItem } from '@/common/define';

export default class Utils {
  static deepClone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  static getPersistAppState() {
    const persistState = localStorage.getItem('persist:root');
    const rootState = persistState ? JSON.parse(persistState) : {};
    /* prettier-ignore */
    const persistAppState: any = rootState['app'] ? JSON.parse(rootState['app']) : {};
    return persistAppState;
  }

  static isTokenValid(token: string) {
    if (!token || typeof token !== 'string') {
      return false;
    }

    const decoded = jwtDecode(token);

    if (!decoded.exp) {
      return false;
    }

    const now = new Date().valueOf();

    return now < decoded.exp * 1000;
  }

  static convertISODateToLocalTime(isoDateString: string) {
    const date = new Date(isoDateString);
    const timestampWithOffset = date.getTime();
    const offset = date.getTimezoneOffset() * 60 * 1000;
    const timestampWithoutOffset = timestampWithOffset - offset;
    const dateWithoutOffset = new Date(timestampWithoutOffset);
    return dateWithoutOffset;
  }

  static createUUID = () => uuidv4();

  static stringToColour = (str?: string) => {
    if (!str) {
      str = uuidv4();
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - colour.length) + colour;
  };

  static spitFullNameIntoFirstMiddleLastName = (name: string) => {
    const parts = name.split(' ');
    const firstName = parts.length > 0 ? parts.pop()?.trim() : '';
    const lastName = parts.length > 0 ? parts.shift()?.trim() : '';
    const middleName = parts.join(' ')?.trim();
    return {
      firstName,
      middleName,
      lastName,
    };
  };

  static errorHandling(error: any) {
    console.log(JSON.stringify(error));
    if (error.errorCode && error.msg) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.msg),
      });
      return;
    }
    if (typeof error.response === 'string') {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.response),
      });
      return;
    }
    if (error.status === 401) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t('Token expired'),
      });
      return;
    }
    if (error.status === 404) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.message || 'Not Found'),
      });
      return;
    }
    if (error.status === 403) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t('Forbidden'),
      });
      return;
    }
    if (error?.response?.error_description) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.response.error_description),
      });
      return;
    }
    if (error.response?.error) {
      notification.error({
        message: i18next.t('notification'),
        description: i18next.t(error.response?.error),
      });
      return;
    }
    // TODO:
    notification.error({
      message: i18next.t('notification'),
      description: i18next.t('An error occurred while processing your request'),
    });
  }

  static successNotification(message?: string) {
    notification.success({
      message: i18next.t('notification'),
      description: i18next.t(message || 'Saved successfully'),
    });
  }

  static formatDateTimeStamp(date: Date): any | null {
    const typeFormat = 'DD/MM/YYYY';
    return dayjs(date).format(typeFormat);
  }

  static concatFullName = (firstName: string, middleName: string, lastName: string) => {
    return [lastName?.trim(), middleName?.trim(), firstName?.trim()].filter(x => x).join(' ');
  };

  static roundDecimal = (value: number, precision = 0) => {
    if (precision % 1 !== 0) return NaN;

    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  static getTime = (timeString: string): { hours: string; minutes: string } => {
    if (!timeString || timeString.trim().length === 0) {
      return {
        hours: '00',
        minutes: '00',
      };
    }

    // Extract hours and minutes from the input string
    const [hours, minutes] = timeString.split(':');

    return {
      hours,
      minutes,
    };
  };
  static minutesToDateString = (totalMinutes: number) => {
    const convertTime = (totalMinutes: number): { hours: number; minutes: number } => {
      let hours = 0,
        minutes = 0;

      // Calculate hours
      hours = Math.floor(totalMinutes / 60);

      // Calculate minutes
      minutes = totalMinutes % 60;

      return {
        hours,
        minutes,
      };
    };

    const { hours, minutes } = convertTime(totalMinutes);

    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');

    return {
      toHH: () => `${hoursString}`,
      toHHMM: () => `${hoursString}:${minutesString}`,
      toHHMMss: () => `${hoursString}:${minutesString}:00`,
    };
  };
}

export const hasPermission = (userPermissions: { [key: string]: boolean }, requiredPermissions: string[]): boolean => {
  return requiredPermissions.some(permission => userPermissions[permission] === true);
};

export const getAuthMenuItems = (items: MenuItem[], userPermissions: { [key: string]: boolean }): MenuItem[] => {
  return items
    .filter(item => !item.auth || hasPermission(userPermissions, item.auth))
    .map(item => {
      if (item.children) {
        return {
          ...item,
          children: getAuthMenuItems(item.children, userPermissions),
        };
      }
      return item;
    })
    .filter(item => !item.children || item.children?.length > 0);
};
