import React, { useContext, useEffect, useRef, useState } from 'react';

import type { FormInstance, InputRef } from 'antd';
import { Form, Input } from 'antd';
import './index.less';
import { Rule } from 'antd/es/form';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  rules?: Rule[];
  placeholder?: string;
  formatValue?: (value: any) => any;
  handleSave: (record: Item) => void;
}

export const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  placeholder,
  formatValue,
  handleSave,
  rules,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      if (formatValue) {
        values[dataIndex] = formatValue(values[dataIndex]?.toString());
      }
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo: any) {
      const cellError = (errInfo?.errorFields || []).find((field: any) => field?.name?.includes(dataIndex));
      if (!cellError) {
        toggleEdit();
      }
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} placeholder={placeholder} />
      </Form.Item>
    ) : (
      // eslint-disable-next-line
      <div className="editable-cell-value-wrap" style={{ paddingInlineEnd: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
