import { combineEpics } from 'redux-observable';

import { appEpics } from './app';
import { employeeEpics } from './employee';
import { issueEpics } from './issue';
import { projectEpics } from './project';
import { shiftEpics } from './shift';
import { teamEpics } from './team';
import { timekeepingEpics } from './timekeeping';
import { userEpics } from './user';

const rootEpics = combineEpics(
  ...appEpics,
  ...userEpics,
  ...timekeepingEpics,
  ...issueEpics,
  ...shiftEpics,
  ...projectEpics,
  ...employeeEpics,
  ...teamEpics
);

export default rootEpics;
