import { catchError, concat, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';

import { projectActions } from './projectSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  AddMemberToProjectModalName,
  CreateManyProjectMemberLoadingKey,
  EditProjectMemberModalName,
  GettingProjectMembers,
  GettingProjectRolesLoadingKey,
  GettingProjectStatusList,
  SavingProject,
  SavingProjectMemberLoadingKey,
} from '@/common/define';
import { ProjectService } from '@/services/ProjectService';
import Utils from '@/utils';

const getProjects$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectsRequest.match),
    switchMap(_action => {
      return concat(
        [startLoading({ key: 'getProjects' })],
        ProjectService.Get.getProjects().pipe(
          switchMap(result => {
            return [projectActions.setProjectsResponse(result)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setProjectsResponse([])];
          }),
        ),
        [stopLoading({ key: 'getProjects' })],
      );
    }),
  );
};

const getProjectById$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectByIdRequest.match),
    switchMap(action => {
      const id = action.payload;
      return concat(
        [startLoading({ key: 'getProjectById' })],
        ProjectService.Get.getProjectById(id).pipe(
          switchMap(result => {
            return [projectActions.setProjectByIdResponse(result)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setProjectByIdResponse(null)];
          }),
        ),
        [stopLoading({ key: 'getProjectById' })],
      );
    }),
  );
};

const getProjectsByCompanyId$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectsByCompanyIdRequest.match),
    switchMap(action => {
      const id = action.payload;
      return concat(
        [startLoading({ key: 'getProjectsByCompanyId' })],
        ProjectService.Get.getProjectsByCompanyId(id).pipe(
          switchMap(result => {
            return [
              projectActions.setProjectList(result),
              // projectActions.setProjectsByCompanyIdResponse(result)
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              projectActions.setProjectList([]),
              // projectActions.setProjectsByCompanyIdResponse([])
            ];
          }),
        ),
        [stopLoading({ key: 'getProjectsByCompanyId' })],
      );
    }),
  );
};

const getEmployeesByCompanyId$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getEmployeesByCompanyIdRequest.match),
    switchMap(action => {
      const companyId = action.payload;
      return concat(
        [startLoading({ key: 'getEmployeesByCompanyId' })],
        ProjectService.Get.getEmployeesByCompanyId(companyId).pipe(
          switchMap(response => {
            return [projectActions.setEmployeesByCompanyIdResponse(response.results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setEmployeesByCompanyIdResponse([])];
          }),
        ),
        [stopLoading({ key: 'getEmployeesByCompanyId' })],
      );
    }),
  );
};

const getRolesByCompanyId$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getRolesByCompanyIdRequest.match),
    switchMap(action => {
      const companyId = action.payload;
      return concat(
        [startLoading({ key: 'getRolesByCompanyId' })],
        ProjectService.Get.getRolesByCompanyId(companyId).pipe(
          switchMap(response => {
            return [projectActions.setRolesByCompanyIdResponse(response.results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setRolesByCompanyIdResponse([])];
          }),
        ),
        [stopLoading({ key: 'getRolesByCompanyId' })],
      );
    }),
  );
};

const createProject$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.createProjectRequest.match),
    switchMap(action => {
      const data = action.payload;
      return concat(
        [startLoading({ key: 'createProject' })],
        ProjectService.Post.createProject(data).pipe(
          switchMap(result => {
            return [
              projectActions.setCreateProjectData(data),
              projectActions.setCreateProjectCurrentStep(3),
              projectActions.setCreateProjectResponse(result),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              projectActions.setCreateProjectData(data),
              projectActions.setCreateProjectCurrentStep(0),
              projectActions.setCreateProjectResponse(null),
            ];
          }),
        ),
        [stopLoading({ key: 'createProject' })],
      );
    }),
  );
};

const updateProjectRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.updateProjectRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, data, companyId } = action.payload;
      const search = state.project.queryParams;
      return concat(
        [startLoading({ key: SavingProject })],
        ProjectService.Put.updateProject(projectId, data).pipe(
          switchMap(result => {
            return ProjectService.Get.getProjectsByCompanyId(companyId, { search }).pipe(
              mergeMap(projects => {
                const updatedProject = projects.find((x: any) => x.id === projectId);
                Utils.successNotification();
                return [projectActions.setSelectedProject(updatedProject), projectActions.setProjectList(projects)];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingProject })],
      );
    }),
  );
};

const getStatusListRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getStatusListRequest.match),
    switchMap(action => {
      const { queryParams } = action.payload;
      return concat(
        [startLoading({ key: GettingProjectStatusList })],
        ProjectService.Get.getProjectStatusList({ search: queryParams }).pipe(
          map(statuses => projectActions.setProjectStatuses(statuses)),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [projectActions.setProjectStatuses(undefined)];
          }),
        ),
        [stopLoading({ key: GettingProjectStatusList })],
      );
    }),
  );
};

const getProjectMembersRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectMembersRequest.match),
    switchMap(action => {
      const { projectId, queryParams } = action.payload;
      return concat(
        [startLoading({ key: GettingProjectMembers })],
        ProjectService.Get.getProjectMembers(projectId, { search: queryParams }).pipe(
          map(members => {
            return projectActions.setProjectMembers(members);
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [projectActions.setProjectMembers(undefined)];
          }),
        ),
        [stopLoading({ key: GettingProjectMembers })],
      );
    }),
  );
};

const getProjectRolesRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectRolesRequest.match),
    switchMap(action => {
      const { queryParams } = action.payload;
      return concat(
        [startLoading({ key: GettingProjectRolesLoadingKey })],
        ProjectService.Get.getProjectRoles({ search: queryParams }).pipe(
          mergeMap(roles => {
            return [projectActions.setProjectRoles(roles)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [projectActions.setProjectRoles(undefined)];
          }),
        ),
        [stopLoading({ key: GettingProjectRolesLoadingKey })],
      );
    }),
  );
};

export const createManyProjectMembers$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.createManyProjectMemberRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { members } = action.payload;
      const { selectedProject, queryParams } = state.project;
      if (!selectedProject) {
        return [];
      }
      return concat(
        [startLoading({ key: CreateManyProjectMemberLoadingKey })],
        ProjectService.Post.createManyProjectMembers(members).pipe(
          switchMap(() => {
            return ProjectService.Get.getProjectMembers(selectedProject.id, { search: queryParams }).pipe(
              mergeMap(projMembers => {
                Utils.successNotification();
                return [projectActions.setProjectMembers(projMembers), hideModal({ key: AddMemberToProjectModalName })];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [projectActions.setProjectMembers(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: CreateManyProjectMemberLoadingKey })],
      );
    }),
  );
};

export const removeProjectMemberRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.removeProjectMemberRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employeeId } = action.payload;
      const { selectedProject, queryParams } = state.project;
      if (!selectedProject) {
        return [];
      }
      return concat(
        [startLoading({ key: SavingProject })],
        ProjectService.Delete.removeProjectMember(selectedProject.id, employeeId).pipe(
          switchMap(() => {
            return ProjectService.Get.getProjectMembers(selectedProject.id, { search: queryParams }).pipe(
              mergeMap(projMembers => {
                Utils.successNotification('Removed successfully');
                return [projectActions.setProjectMembers(projMembers), hideModal({ key: AddMemberToProjectModalName })];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [projectActions.setProjectMembers(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingProject })],
      );
    }),
  );
};

export const updateProjectMemberRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.updateProjectMemberRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employeeId, member } = action.payload;
      const { selectedProject, queryParams } = state.project;
      if (!selectedProject) {
        return [];
      }
      return concat(
        [startLoading({ key: SavingProjectMemberLoadingKey })],
        ProjectService.Put.updateProjectMember(selectedProject.id, employeeId, member).pipe(
          switchMap(() => {
            return ProjectService.Get.getProjectMembers(selectedProject.id, { search: queryParams }).pipe(
              mergeMap(projMembers => {
                Utils.successNotification();
                return [projectActions.setProjectMembers(projMembers), hideModal({ key: EditProjectMemberModalName })];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [projectActions.setProjectMembers(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingProjectMemberLoadingKey })],
      );
    }),
  );
};

export const projectEpics = [
  getProjects$,
  getProjectById$,
  getProjectsByCompanyId$,
  getEmployeesByCompanyId$,
  getRolesByCompanyId$,
  createProject$,
  updateProjectRequest$,
  getStatusListRequest$,
  getProjectMembersRequest$,
  getProjectRolesRequest$,
  createManyProjectMembers$,
  removeProjectMemberRequest$,
  updateProjectMemberRequest$
];
