const apiUrl = `https://tingop.tingconnect.com`;

interface EnvVars {
  dev: EnvVar;
  prod: EnvVar;
}

interface EnvVar {
  identityUrl: string;
  apiUrl: string;
  checkInUrl: string,
  companyId: number,
  oAuthConfig: {
    issuer: string;
    clientId: string;
    scope: string;
    clientSecret?: string;
  };
  localization: {
    defaultResourceName: string;
  };
}

const ENV: EnvVars = {
  dev: {
    identityUrl: 'https://tingop.tingconnect.com',
    apiUrl: 'https://tingop.tingconnect.com',
    checkInUrl: 'https://sit.checkin.tingconnect.com',
    companyId: 1,
    oAuthConfig: {
      issuer: apiUrl,
      clientId: 'TingOp',
      scope: 'offline_access API',
      clientSecret: 'ConstruxivViewersecret',
    },
    localization: {
      defaultResourceName: 'hicas',
    },
  },
  prod: {
    identityUrl: 'https://tingop.tingconnect.com',
    apiUrl: 'https://tingop.tingconnect.com',
    checkInUrl: 'https://sit.checkin.tingconnect.com',
    companyId: 1,
    oAuthConfig: {
      issuer: apiUrl,
      clientId: 'TingOp',
      scope: 'offline_access API',
      clientSecret: 'ConstruxivViewersecret',
    },
    localization: {
      defaultResourceName: 'hicas',
    },
  },
};

export const getEnvVars = () => {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV === 'development' ? ENV.dev : ENV.prod;
};
