import React, { useEffect, useState } from 'react';

import {
  RightOutlined,
  LeftOutlined,
  HomeOutlined,
  // eslint-disable-next-line
  DashboardOutlined,
  SettingOutlined,
  TeamOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Layout, Menu, MenuProps, SiderProps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LeftSider.module.less';
import './leftPanel.css';
import { LeftPanelWidth, MenuItem } from '@/common/define';
// eslint-disable-next-line
import { IconSvg } from '@/components';
import { getEnvVars } from '@/environment';
import ProjectBg from '@/image/icon/project.png';
import { appActions, getActiveMenu, getGrantedPolicies } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';
import { getDefaultOrganization } from '@/store/user';
import { getAuthMenuItems } from '@/utils';

const { Sider } = Layout;

const { apiUrl } = getEnvVars();

export const LeftSider = (props: SiderProps) => {
  const { ...rest } = props;

  const { t } = useTranslation(['layout', 'organization']);

  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const selectedProject = useAppSelector(getSelectedProject());
  const activeMenu = useAppSelector(getActiveMenu());
  const defaultOrganization = useAppSelector(getDefaultOrganization());
  const grantedPolicies = useAppSelector(getGrantedPolicies());

  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState(['/projects/employees']);

  const mainMenu: MenuItem[] = [
    // {
    //   label: t('Home'),
    //   icon: <FileDoneOutlined />,
    //   key: '/',
    // },
    {
      label: t('Projects'),
      icon: <HomeOutlined />,
      key: '/projects',
      auth: ['DuAn.View']
    },
    {
      label: t('Employee'),
      icon: <TeamOutlined />,
      key: '/employee',
      children: [
        {
          label: t('Company employee'),
          key: '/employee/company-employee',
          auth: ['CongTy.NhanSu.View']
        },
        {
          label: t('Company group'),
          key: '/employee/company-group',
          auth: ['CongTy.PhongBan.View']
        },
      ],
    },
    {
      label: t('Shift templates'),
      icon: <FieldTimeOutlined />,
      key: '/shift-templates',
      auth: ['CaLamViec.View']
    },
  ];

  const projectMenu: MenuItem[] = [
    // {
    //   label: t('Dashboard'),
    //   icon: <DashboardOutlined />,
    //   key: '/projects/dashboard',
    // },
    // {
    //   label: t('Bidding'),
    //   icon: (
    //     <i>
    //       <IconSvg name="bidding" width={20} />
    //     </i>
    //   ),
    //   key: '/projects/bidding',
    // },
    // {
    //   label: t('Contract, Bidding KPIs'),
    //   icon: (
    //     <i>
    //       <img src="/icons/hopdongkips.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/contract-bid-kpis',
    // },
    // {
    //   label: t('Construction manuals'),
    //   key: '/projects/construction/manuals',
    //   icon: (
    //     <i>
    //       <img src="/icons/hopdongkips.png" width={18} alt="" />
    //     </i>
    //   ),
    // },
    // {
    //   label: t('Prepare for construction'),
    //   icon: (
    //     <i>
    //       <img src="/icons/chuanbithicong.png" width={16} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/prepare',
    //   children: [
    //     {
    //       label: t('Preparation work in construction'),
    //       key: '/projects/prepare/construction',
    //     },
    //     {
    //       label: t('Cost estimate'),
    //       key: '/projects/prepare/cost-estimate',
    //     },
    //   ],
    // },
    // {
    //   label: t('Construction'),
    //   icon: (
    //     <i>
    //       <img src="/icons/thicong.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/construction',
    //   children: [
    //     {
    //       label: t('Initial setup process'),
    //       key: '/projects/construction/init',
    //     },
    //     {
    //       label: t('Weekly assignment'),
    //       key: '/projects/construction/weekly-assignment',
    //     },
    //     {
    //       label: t('Construction diary'),
    //       key: '/projects/construction/diary',
    //     },
    //     {
    //       label: t('Labor safety diary'),
    //       key: '/projects/construction/labor-safety-diary',
    //     },
    //     {
    //       label: t('Enviromental hygiene diary'),
    //       key: '/projects/construction/enviromental-hygiene-diary',
    //     },
    //   ],
    // },
    // {
    //   label: t('Store - Material - Machinery'),
    //   icon: (
    //     <i>
    //       <img src="/icons/khovattu.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects',
    //   children: [
    //     {
    //       label: t('Main material'),
    //       key: '/projects/main-material',
    //     },
    //     {
    //       label: t('Auxiliary material'),
    //       key: '/projects/auxiliary-material',
    //     },
    //     {
    //       label: t('Machinery'),
    //       key: '/projects/machinery',
    //     },
    //     {
    //       label: t('Materials log'),
    //       key: '/projects/materials-log',
    //     },
    //     {
    //       label: t('Machinery log'),
    //       key: '/projects/machinery-log',
    //     },
    //     {
    //       label: t('Transfer material'),
    //       key: '/projects/transfer-material',
    //     },
    //     {
    //       label: t('Depot'),
    //       key: '/projects/depot',
    //     },
    //   ],
    // },
    // {
    //   label: t('Subcontractors'),
    //   icon: (
    //     <i>
    //       <img src="/icons/thauphu.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/subcontractors',
    //   children: [
    //     {
    //       label: t('Subcontract'),
    //       key: '/projects/subcontractors/subcontract',
    //     },
    //     {
    //       label: t('Pay the subcontractor 12'),
    //       key: '/projects/subcontractors/pay-the-subcontractor-12',
    //     },
    //     {
    //       label: t('Pay the subcontractor 17'),
    //       key: '/projects/subcontractors/pay-the-subcontractor-17',
    //     },
    //     {
    //       label: t('Aggregate costs'),
    //       key: '/projects/subcontractors/aggregate-costs',
    //     },
    //   ],
    // },
    {
      label: t('Check-in'),
      icon: (
        <i>
          <img src="/icons/chamcong.png" width={18} alt="" />
        </i>
      ),
      key: '/projects/check-in',
      children: [
        {
          label: t('Check-in time'),
          key: '/projects/employees/check-in-time',
          auth: ['ChamCong.View']
        },
        {
          label: t('Overtime'),
          key: '/projects/employees/overtime',
        },
      ],
    },
    {
      label: t('Employees'),
      icon: (
        <i>
          <img src="/icons/nhansu.png" width={18} alt="" />
        </i>
      ),
      key: '/projects/employees',
      children: [
        // {
        //   label: t('Personnel transfer'),
        //   key: '/projects/employees/transfer',
        // },
        // {
        //   label: t('DM statistic - Bonus'),
        //   key: '/projects/employees/statistic-bonus',
        // },
        {
          label: t('Team manage'),
          key: '/projects/employees/team-manage',
          auth: ['QuanLyToDoi.View']
        },
      ],
    },
    // {
    //   label: t('Union welfare funds'),
    //   icon: (
    //     <i>
    //       <img src="/icons/quycongdoan.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/union-welfare-funds',
    //   children: [
    //     {
    //       label: t('Union fee collection report'),
    //       key: '/projects/union-welfare-funds/fee',
    //     },
    //     {
    //       label: t('Union funds expenses report'),
    //       key: '/projects/union-welfare-funds/expenses',
    //     },
    //     {
    //       label: t('Tet and holiday bonus expenses'),
    //       key: '/projects/union-welfare-funds/bonus',
    //     },
    //     {
    //       label: t('Recurring travel expenses'),
    //       key: '/projects/union-welfare-funds/recurring-travel-expenses',
    //     },
    //   ],
    // },
    // {
    //   label: t('Management accounting'),
    //   icon: (
    //     <i>
    //       <img src="/icons/ktquantri.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/management-accounting',
    //   children: [
    //     {
    //       label: t('Make an advance plan'),
    //       key: '/projects/management-accounting/make-an-advance-plan-12th',
    //     },
    //     {
    //       label: t('Payment plan'),
    //       key: '/projects/management-accounting/20th-payment-plan',
    //     },
    //     {
    //       label: t('Aggregate materials'),
    //       key: '/projects/management-accounting/aggregate-materials',
    //     },
    //     {
    //       label: t('Track cash flow'),
    //       key: '/projects/management-accounting/track-cash-flow',
    //     },
    //     {
    //       label: t('Bookkeeping'),
    //       key: '/projects/management-accounting/bookkeeping',
    //     },
    //     {
    //       label: t('Input invoices'),
    //       key: '/projects/management-accounting/input-invoices',
    //     },
    //     {
    //       label: t('Output invoices'),
    //       key: '/projects/management-accounting/output-invoices',
    //     },
    //     {
    //       label: t('Invoices difference'),
    //       key: '/projects/management-accounting/invoices-difference',
    //     },
    //     {
    //       label: t('Invoice X'),
    //       key: '/projects/management-accounting/invoice-x',
    //     },
    //     {
    //       label: t('ĐMDT / HĐ đầu vào + X'),
    //       key: '/projects/management-accounting/input-invoices-and-x',
    //     },
    //     {
    //       label: t('Compare norms'),
    //       key: '/projects/management-accounting/compare-norms',
    //     },
    //     {
    //       label: t('Supplier - Investor debt'),
    //       key: '/projects/management-accounting/supplier-investor-debt',
    //     },
    //     {
    //       label: t('Profit and loss statement'),
    //       key: '/projects/management-accounting/profit-and-loss-statement',
    //     },
    //     // {
    //     //   label: t('Development chart'),
    //     //   key: '/projects/management-accounting/develoment-chart',
    //     // },
    //     // {
    //     //   label: t('Other cost charts'),
    //     //   key: '/projects/management-accounting/Other-cost-charts',
    //     // },
    //     // {
    //     //   label: t('Profit chart'),
    //     //   key: '/projects/management-accounting/profit-chart',
    //     // },
    //   ],
    // },
    // {
    //   label: t('Project management'),
    //   icon: (
    //     <i>
    //       <img src="/icons/quanlyduan.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/project-management',
    //   children: [
    //     {
    //       label: t('Project documents'),
    //       key: '/projects/project-management/documents',
    //     },
    //     {
    //       label: t('Construction expenses'),
    //       key: '/projects/project-management/contruction-expenses',
    //     },
    //   ],
    // },
    // {
    //   label: t('KPI - Salary'),
    //   icon: (
    //     <i>
    //       <img src="/icons/kpiluong.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/kpi-salary',
    //   children: [
    //     {
    //       label: t('KPIs of departments'),
    //       key: '/projects/kpi-salary/kpis-of-departments',
    //     },
    //     {
    //       label: t('Salary of departments'),
    //       key: '/projects/kpi-salary/salary-of-departments',
    //     },
    //     {
    //       label: t('1st salary advance'),
    //       key: '/projects/kpi-salary/1st-salary-advance',
    //     },
    //     {
    //       label: t('1st salary payment'),
    //       key: '/projects/kpi-salary/1st-salary-payment',
    //     },
    //     {
    //       label: t('2nd salary advance'),
    //       key: '/projects/kpi-salary/2nd-salary-advance',
    //     },
    //     {
    //       label: t('2nd salary payment'),
    //       key: '/projects/kpi-salary/2nd-salary-payment',
    //     },
    //     {
    //       label: t('Year-end bonus'),
    //       key: '/projects/kpi-salary/year-end-bonus',
    //     },
    //   ],
    // },
    // {
    //   label: t('Review'),
    //   icon: (
    //     <i>
    //       <img src="/icons/danhgia.png" width={18} alt="" />
    //     </i>
    //   ),
    //   key: '/projects/review',
    //   children: [
    //     {
    //       label: t('Project management - suppliers'),
    //       key: '/projects/kpi-salary/project-management-suppliers',
    //     },
    //     {
    //       label: t('Supervision consultants - supplier'),
    //       key: '/projects/kpi-salary/supervision-consultants-suppliers',
    //     },
    //     {
    //       label: t('Investors - Project management'),
    //       key: '/projects/kpi-salary/investors-project-management',
    //     },
    //     {
    //       label: t('Investors - Supervision consultants'),
    //       key: '/projects/kpi-salary/investors-supervision-consultant',
    //     },
    //     {
    //       label: t('Other'),
    //       key: '/projects/kpi-salary/Other',
    //     },
    //   ],
    // },
  ];

  const bottomMenu: MenuItem[] = [
    {
      label: t('Project settings'),
      icon: <SettingOutlined />,
      key: '/projects/project-settings',
      auth: ['CaiDat.ThongTinChung.View']
    },
  ];

  const authMainMenu = getAuthMenuItems(mainMenu, grantedPolicies);
  const authProjectMenu = getAuthMenuItems(projectMenu, grantedPolicies);
  const authBottomMenu = getAuthMenuItems(bottomMenu, grantedPolicies);

  useEffect(() => {
    const { pathname } = location;
    const menus: any = authProjectMenu.concat(authBottomMenu).concat(authMainMenu);
    for (const item of menus) {
      if (item?.key === pathname) {
        const { label, key } = item;
        dispatch(appActions.setActiveMenu({ label, key }));
      }
      if (item?.children) {
        for (const child of item.children) {
          if (child.key === pathname) {
            const { label, key } = child;
            dispatch(appActions.setActiveMenu({ label, key }));
            if (!collapsed) {
              setOpenKeys([item.key]);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [location, collapsed]);

  const onClickMenu = (menu: any) => {
    const { key } = menu;
    navigate(key);
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const handleLeftPanelVisibility = () => {
    setCollapsed(prev => !prev);
  };

  return (
    <Sider
      breakpoint="lg"
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={LeftPanelWidth}
      onCollapse={setCollapsed}
      className={styles.main}
      {...rest}
    >
      <div className={styles.menusWrapper}>
        <div
          style={{
            display: 'flex',
            margin: selectedProject ? 5 : 0,
            height: !selectedProject ? (collapsed ? 40 : 0) : collapsed ? 30 : 65,
          }}
        >
          {selectedProject && (
            <>
              <Avatar
                shape="square"
                size={64}
                src={selectedProject?.avatar ? `${apiUrl}/Projects${selectedProject?.avatar}` : ProjectBg}
                style={{ display: collapsed ? 'none' : 'block' }}
              />
              <div style={{ marginLeft: 5, paddingTop: 5, display: collapsed ? 'none' : 'block' }}>
                <b>{selectedProject?.name}</b>
              </div>
            </>
          )}
          <Button
            shape="circle"
            size="small"
            onClick={handleLeftPanelVisibility}
            className={styles.toggleButton}
            icon={collapsed ? <RightOutlined style={{ fontSize: 11 }} /> : <LeftOutlined style={{ fontSize: 11 }} />}
            style={{ transform: collapsed ? 'translateX(-110%)' : 'translateX(-10%)' }}
          />
        </div>
        {collapsed && selectedProject && (
          <div style={{ marginTop: 5, marginLeft: 7 }}>
            <Tooltip title={selectedProject?.name}>
              <Avatar
                shape="square"
                size={64}
                src={selectedProject?.avatar ? `${apiUrl}/Projects${selectedProject?.avatar}` : ProjectBg}
              />
            </Tooltip>
          </div>
        )}
        {!selectedProject && defaultOrganization && (
          <Menu
            mode="inline"
            onClick={({ key }) => {
              navigate(key);
            }}
            selectedKeys={[activeMenu?.key]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={authMainMenu}
            inlineIndent={10}
            className={`${styles.top_menu} ${styles.custom_scrollbar} left_menu`}
          />
        )}
        {selectedProject && defaultOrganization && (
          <>
            <Menu
              mode="inline"
              onClick={onClickMenu}
              selectedKeys={[activeMenu?.key]}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              items={authProjectMenu}
              inlineIndent={5}
              className={`${styles.top_menu} ${styles.custom_scrollbar} left_menu`}
            />
            <Menu
              mode="inline"
              onClick={onClickMenu}
              selectedKeys={[activeMenu?.key]}
              items={authBottomMenu}
              inlineIndent={5}
              className={styles.bottom_menu}
            />
          </>
        )}
      </div>
    </Sider>
  );
};
