import React, { useEffect, useState } from 'react';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './components.module.less';
import { CreateUpdateEmployeeModalName } from '@/common/define';
import { WithPermission } from '@/hocs/PermissionHOC';
import { getCurrentCompany } from '@/store/app';
import { employeeActions, getEmployeeQueryParams } from '@/store/employee';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { showModal } from '@/store/modal';

export const CompanyEmployeeHeader = () => {
  const { t } = useTranslation('employee');
  const dispatch = useAppDispatch();
  const params = useAppSelector(getEmployeeQueryParams());
  const company = useAppSelector(getCurrentCompany());
  const [timer, setTimer] = useState<any>(null);
  const [searchStr, setSearchStr] = useState(params?.search);

  useEffect(() => {
    setSearchStr(params?.search);
  }, [params]);

  const onSearchChange = (evt: any) => {
    const search = evt.target.value;
    setSearchStr(search);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(employeeActions.getEmployeesRequest({ companyId: company.id, params: { ...params, page: 1, search } }));
    }, 500);
    setTimer(timeoutId);
  };

  const createEmployee = () => {
    dispatch(showModal({ key: CreateUpdateEmployeeModalName }));
  };

  return (
    <div className={styles.functionContainer}>
      <Input
        placeholder={t('companyEmployee.findEmployee')}
        allowClear
        value={searchStr}
        onChange={onSearchChange}
        style={{ width: 300 }}
        suffix={searchStr ? null : <SearchOutlined />}
      />
      <WithPermission policyKeys={['CongTy.NhanSu.Create']} strategy={'disable'}>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size={'middle'} onClick={createEmployee}>
          {t('companyEmployee.addEmployee')}
        </Button>
      </WithPermission>
    </div>
  );
};
