import { MenuProps, UploadFile } from 'antd';
import { JwtPayload } from 'jwt-decode';
import { useTranslation } from 'react-i18next';

export interface LoginInput {
  username: string;
  password: string;
  captchaId?: string;
  captcha?: string;
  remember: boolean;
}

export interface LoginPayload {
  grant_type: string;
  scope: string;
  username: string;
  password: string;
  client_id: string;
  client_secret?: string;
}

export interface JwtDecoded extends JwtPayload {
  profile: string;
  role: string[];
  orgRoles: string[];
  CompanyId: number;
  OrgId: string;
  Permissions?: string[];
}

export const NavbarHeight = 70;
export const LeftPanelWidth = 240;

export const formatDateDisplay = 'DD/MM/YYYY';
export const formatTimeOnly = 'HH:mm:ss';
export const apiDateParamsFormat = 'YYYY-MM-DD';
export const apiDateTimeParamsFormat = 'YYYY-MM-DD HH:mm:ss.SSS';

export interface Project {
  id: number;
  external_id: number;
  title: string;
  address: string;
  status?: ProjectStatus;
  photoUrl?: string;
}

export enum ProjectStatus {
  BIDDING = 'bidding',
  COMPLETED = 'completed',
  EXECUTING = 'executing',
}

export const persitConfigKey = 'persitConfig';

export type MenuItem = Required<MenuProps>['items'][number] & {
  auth?: string[];
  children?: MenuItem[];
};

/**
 * copy icon svg vào thu mục icons trong public
 * sau đó thêm enum là tên của icon svg vào đây để sử dụng
 */
enum IconSvgEnum {
  bidding,
  contract,
  prepareConstruction,
}

/**
 * copy icon svg vào thu mục icons trong public
 * sau đó thêm enum là tên của icon svg vào IconSvgEnum trong common/define.ts để sử dụng
 */
export type IconSvgType = keyof typeof IconSvgEnum;

export interface ProjectMemberType {
  key: string;
  name: string;
  role: string[];
}

export interface CheckboxType {
  key: string;
  label: string;
  value: string;
}

export interface ProjectInformationValue {
  projectName: string;
  projectStartDate: Date | null | undefined;
  address: string;
  description: string;
  projectAvatar: UploadFile | null;
  investorName: string;
  investorPhone: string;
  investorEmail: string;
  status: number;
}

export interface MenuInfo {
  key: string;
  keyPath: string[];
  /** @deprecated This will not support in future. You should avoid to use this */
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

export type ViewState = 'List' | 'Gantt' | 'Kanban';

export enum FormType {
  CREATE = 'create',
  UPDATE = 'update',
}

export interface ITeamInformation {
  teamName: string;
  description: string;
  note: string;
}

export interface PagingResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
  // results: any[];
}

export const defaultPagingParams = {
  paging: true,
  page: 1,
  pageSize: 20,
};

export const largePagingParams = {
  paging: true,
  page: 1,
  pageSize: 2000,
};

export enum NatureOfWorkEnum {
  NORMAL = 0,
  DAILYWORK = 1,
}

export const NatureOfWorks = () => {
  const { t } = useTranslation('bidding');

  return [
    {
      label: t('Normal work'),
      value: NatureOfWorkEnum.NORMAL,
    },
    {
      label: t('Daily work'),
      value: NatureOfWorkEnum.DAILYWORK,
    },
  ];
};

export const WorkingProgress = [
  {
    label: '0%',
    value: 0,
  },
  {
    label: '10%',
    value: 10,
  },
  {
    label: '20%',
    value: 20,
  },
  {
    label: '30%',
    value: 30,
  },
  {
    label: '40%',
    value: 40,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '60%',
    value: 60,
  },
  {
    label: '70%',
    value: 70,
  },
  {
    label: '80%',
    value: 80,
  },
  {
    label: '90%',
    value: 90,
  },
  {
    label: '100%',
    value: 100,
  },
];

export const FormatDateAPI = 'YYYY-MM-DD';
export const DateEmptyString = '0001-01-01T00:00:00';

export const CreateUpdateEmployeeModalName = 'CreateUpdateEmployeeModal';
export const GettingEmployeeList = 'GettingEmployeeList';
export const SavingEmployee = 'SavingEmployee';
export const RemovingEmployee = 'RemovingEmployee';

export const GettingProjectStatusList = 'GettingProjectStatusList';
export const SavingProject = 'SavingProject';
export const GettingProjectMembers = 'GettingProjectMembers';

export const CreateUpdateTeamModalName = 'CreateUpdateTeamModal';
export const IsUpdateTeamModal = 'IsUpdateTeamModal';
export const GettingTeams = 'GettingTeams';
export const GettingTeamDetails = 'GettingTeamDetails';
export const SavingTeam = 'SavingTeam';
export const RemovingTeam = 'RemovingTeam';

export const AddMemberToTeamModalName = 'AddMemberToTeamModal';
export const SavingTeamMembers = 'SavingTeamMembers';
export const RemovingMemberFromTeam = 'RemovingMemberFromTeam';

export const AddMemberToProjectModalName = 'AddMemberToProjectModalName';
export const GettingProjectRolesLoadingKey = 'GettingProjectRolesKey';
export const CreateManyProjectMemberLoadingKey = 'CreateManyProjectMemberLoadingKey';
export const EditProjectMemberModalName = 'EditProjectMemberModalName';
export const SavingProjectMemberLoadingKey = 'SavingProjectMemberLoadingKey';

export const CreateUpdateIssueModalName = 'CreateUpdateIssueModal';
export const GettingIssueList = 'GettingIssueeList';
export const SavingIssue = 'SavingIssue';
export const RemovingIssue = 'RemovingIssue';
export const GettingIssueStatusList = 'GettingIssueStatusList';
export const GettingIssueProgressList = 'GettingIssueProgressList';
