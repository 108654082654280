import React, { useEffect, useMemo, useState } from 'react';

import { DatePicker, Flex, Input, Modal, ModalProps, Select, Space, Table, TableProps, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ApproveAttendanceModalName, apiDateParamsFormat, formatDateDisplay } from '@/common';
import { EditableCell, EditableRow } from '@/components/Table/Editable';
import { useWindowSize } from '@/hooks';
import { CheckInResponse, FaceCheckService } from '@/services/CheckInService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getModalVisible, hideModal } from '@/store/modal';
import { getTeams, timekeepingActions } from '@/store/timekeeping';
import Utils from '@/utils';

interface QueryParams {
  teamId?: number;
  workingDay: string;
  search?: string;
}
type ColumnTypes = Exclude<TableProps<any>['columns'], undefined>;
type ExtraColumnTypes = {
  editable?: boolean;
  dataIndex: string;
  formatValue?: (value: any) => any;
  placeholder?: string;
};
interface ApproveAttendanceModalProps extends ModalProps {}

const rowKey = 'employeeCode';
const requiredKeys = ['approved_Note'];
export const ApproveAttendanceModal = ({ ...props }: ApproveAttendanceModalProps) => {
  const { t } = useTranslation(['common', 'checkin']);
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [innerWidth, innerHeight] = useWindowSize();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    workingDay: dayjs().format(apiDateParamsFormat),
  });
  const [dailyCheckIn, setDailyCheckIn] = useState<CheckInResponse[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const filteredDailyCheckIn = useMemo(() => {
    return dailyCheckIn.filter(checkIn => {
      return checkIn.employee_Name?.toLowerCase().includes(queryParams.search?.toLowerCase() || '');
    });
  }, [dailyCheckIn, queryParams]);

  const isSaving = useAppSelector(getLoading('approveCheckIn'));
  const defaultOpen = useAppSelector(getModalVisible(ApproveAttendanceModalName));
  const open = props.open || defaultOpen;
  const teams = useAppSelector(getTeams());
  const teamsOptions = teams.map(team => ({ label: team.name, value: team.id }));

  const teamShifts = useMemo(() => {
    if (!queryParams?.teamId) return [];
    const foundTeam = teams.find(team => team.id === queryParams.teamId);
    return foundTeam?.shifts || [];
  }, [queryParams, teams]);

  const handleCancel = () => {
    dispatch(hideModal({ key: ApproveAttendanceModalName }));
  };

  useEffect(() => {
    if (open && teams?.length) {
      onChangeTeam(teams[0].id);
    } else if (!open) {
      setDailyCheckIn([]);
    }
  }, [open, teams]);

  useEffect(() => {
    if (filteredDailyCheckIn?.length) {
      const mapData = filteredDailyCheckIn?.map((item: any, index: any) => {
        if (item?.approvedExtra) {
          const { ApprovedShiftTime } = JSON.parse(item.approvedExtra);
          if (!ApprovedShiftTime) {
            return {
              ...item,
            };
          }
          return {
            ...item,
            ...Object.keys(ApprovedShiftTime).reduce(
              (prev, key) => ({
                ...prev,
                [key]: ApprovedShiftTime[key] ? Utils.minutesToDateString(ApprovedShiftTime[key]).toHHMM() : '',
              }),
              {},
            ),
          };
        }
        const { approved_Shift_Hours } = item;
        if (!approved_Shift_Hours) {
          return {
            ...item,
          };
        }
        return {
          ...item,
          ...Object.keys(approved_Shift_Hours).reduce(
            (prev, key) => ({
              ...prev,
              [key]: approved_Shift_Hours[key] ? Utils.minutesToDateString(approved_Shift_Hours[key]).toHHMM() : '',
            }),
            {},
          ),
        };
      });
      setDataSource(mapData);
    } else {
      setDataSource([]);
    }
  }, [filteredDailyCheckIn]);

  const getDailyCheckInRequest = (queryParams: QueryParams) => {
    const { teamId, workingDay } = queryParams;
    setQueryParams(prev => ({ ...prev, ...queryParams }));
    if (!teamId) return;

    setLoading(true);
    FaceCheckService.Get.getDailyCheckInReportByTeamId(teamId, { search: { workingDay } }).subscribe({
      next: res => {
        setDailyCheckIn(res);
      },
      error: err => {
        console.error('getDailyCheckInRequest error:', err);
        setLoading(false);
      },
      complete: () => {
        setLoading(false);
      },
    });
  };

  const onChangeTeam = (teamId: number) => {
    getDailyCheckInRequest({ ...queryParams, teamId });
  };

  const onChangeWorkingDate = (day: Dayjs | null) => {
    if (!day) return;
    getDailyCheckInRequest({ ...queryParams, workingDay: day.format(apiDateParamsFormat) });
  };

  const handleRowSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row?.[rowKey] === item?.[rowKey]);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const formatShiftValue = (value: any) => {
    if (!value) return '';
    // check if number ( value = hours )
    if (!isNaN(Number(value)) && Number(value) <= 24) {
      return Utils.minutesToDateString(Utils.roundDecimal(Number(value), 1) * 60).toHHMM();
    }
    // check if string
    const match = value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
    if (match) {
      const { hours, minutes } = Utils.getTime(value);
      return Utils.minutesToDateString(parseInt(hours) * 60 + parseInt(minutes)).toHHMM();
    }
    return '';
  };

  const checkInColumns: (ColumnTypes[number] & ExtraColumnTypes)[] = teamShifts.map(shift => {
    const dateOnly = queryParams.workingDay;
    const goToWork = dayjs(`${dateOnly}T${shift.startTime}`);
    const getOffWork = dayjs(`${dateOnly}T${shift.endTime}`);
    const timeSuffix = goToWork.isAfter(getOffWork) ? ` ${t('Hôm sau')}` : '';

    return {
      dataIndex: shift.id.toString(),
      key: shift.id,
      title: `${shift.name} (${goToWork.format('HH:mm')} - ${getOffWork.format('HH:mm')}${timeSuffix})`,
      width: 180,
      // for edit
      editable: true,
      placeholder: '01:30 or 1.5',
      formatValue: formatShiftValue,
    };
  });
  const defaultColumns: (ColumnTypes[number] & ExtraColumnTypes)[] = [
    {
      title: t('Employee'),
      dataIndex: 'employee_Name',
      key: 'employee_Name',
      render: (value, _, index) => `${index + 1}. ${value}`,
      fixed: 'left',
      editable: false,
      width: 200,
    },
    ...checkInColumns,
    {
      title: t('Total approve hours', { ns: 'checkin' }),
      dataIndex: 'totalApprovedMainShift',
      key: 'totalApprovedMainShift',
      width: 140,
      render(value, record, index) {
        if (!value) return;
        return Utils.minutesToDateString(value).toHHMM();
      },
    },
    {
      title: t('Approve hours note', { ns: 'checkin' }),
      dataIndex: 'approved_Note',
      key: 'approved_Note',
      editable: true,
      width: 160,
    },
    {
      title: t('Check in note', { ns: 'checkin' }),
      dataIndex: 'checkin_Note',
      key: 'checkin_Note',
      width: 160,
    },
  ];

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      title:
        !col.key || !requiredKeys.includes(col.key.toString()) ? (
          col.title
        ) : (
          <>
            <span style={{ color: '#ff4d4f', lineHeight: 1, marginRight: 4 }}>*</span>
            {col.title}
          </>
        ),
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        // rules: [{ required: col.key && requiredKeys.includes(col.key?.toString()), message: 'Bắt buộc nhập' }],
        placeholder: col.placeholder,
        formatValue: col.formatValue,
        handleSave: handleRowSave,
      }),
    };
  });

  const hasShiftValue = (record: any) => {
    const shiftsId = teamShifts.map(shift => shift.id);
    return shiftsId.some(id => record[id] && record[id] !== '');
  };

  const handleSave = () => {
    let approvedCheckInData = dataSource.filter(item => hasShiftValue(item));
    if (!approvedCheckInData?.length) {
      messageApi.info('Vui lòng nhập dữ liệu chốt giờ cho ca làm việc');
      return;
    }

    let isValid = true;
    let message = '';
    approvedCheckInData.forEach(data => {
      requiredKeys.forEach(key => {
        if (!data[key]) {
          isValid = false;
          if (key === 'approved_Note') {
            message = 'Nhân sự có dữ liệu chốt giờ theo ca phải nhập ghi chú chốt giờ';
          }
        }
      });
    });

    if (!isValid) {
      message && messageApi.info(message);
      return;
    }

    approvedCheckInData = approvedCheckInData.map(data => {
      let TotalApprovedMainShift = 0;
      const approvedShiftTime: { [key: string]: number } = teamShifts.reduce((prev, shift) => {
        if (data[shift.id]) {
          const { hours, minutes } = Utils.getTime(data[shift.id]);
          const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
          TotalApprovedMainShift += totalMinutes;
          return {
            ...prev,
            [shift.id]: totalMinutes,
          };
        } else {
          return { ...prev };
        }
      }, {});

      return {
        working_Day: queryParams.workingDay,
        team_Id: queryParams.teamId,
        face_Identity_Id: data.face_Identity_Id,
        day_Hours: data?.day_Hours,
        approved_Day_Hours: data?.approved_Day_Hours,
        approved_Note: data?.approved_Note || '',
        approvedExtra: JSON.stringify({
          ApprovedShiftTime: approvedShiftTime,
          TotalApprovedMainShift,
        }),
      };
    });

    dispatch(timekeepingActions.approveCheckInByDayRequest({ input: approvedCheckInData }));
  };

  return (
    <Modal
      centered
      okText={'Lưu'}
      width={'1200px'}
      destroyOnClose
      className="modal-timekeeping"
      style={{ width: '300px', ...{ important: 'true' } }}
      confirmLoading={isSaving}
      onCancel={handleCancel}
      onOk={handleSave}
      {...props}
      open={open}
    >
      {contextHolder}
      <div style={{ paddingTop: 16 }}>
        <Flex wrap="wrap" align="center" justify="space-between" gap={10} style={{ marginBottom: 10 }}>
          <div style={{ whiteSpace: 'nowrap', fontWeight: '600', fontSize: 16 }}>Chốt giờ cho nhân sự theo ngày</div>
          <Space size={10} wrap>
            <Input
              placeholder={t('Search employee')}
              allowClear
              value={queryParams.search}
              onChange={e => setQueryParams(prev => ({ ...prev, search: e.target.value || '' }))}
            />
            <Select
              options={teamsOptions}
              value={queryParams.teamId}
              onChange={onChangeTeam}
              style={{ minWidth: 150 }}
            />
            <DatePicker
              format={formatDateDisplay}
              value={queryParams.workingDay ? dayjs(queryParams.workingDay, apiDateParamsFormat) : undefined}
              onChange={onChangeWorkingDate}
              allowClear={false}
            />
          </Space>
        </Flex>
        <Table
          columns={columns as ColumnTypes}
          dataSource={dataSource}
          className="wrapperTable"
          rowKey={rowKey}
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          rowClassName={'editable-row'}
          bordered
          size="small"
          scroll={{ x: 1000, y: innerHeight - 300 }}
          pagination={false}
          loading={loading}
        />
      </div>
    </Modal>
  );
};
