import { catchError, concat, filter, finalize, map, of, switchMap } from 'rxjs';

import { timekeepingActions } from './timekeepingSlice';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import { ApproveAttendanceModalName } from '@/common';
import { FaceCheckService } from '@/services/CheckInService';
import { RequestOptions } from '@/services/types';
import Utils from '@/utils';

const getTeamsOfOperatorRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.getTeamsOfOperatorRequest.match),
    switchMap(action => {
      const { operatorId, accessToken } = action.payload;
      const options: RequestOptions = {};
      if (accessToken) {
        options.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return concat(
        [startLoading({ key: 'getTeams' })],
        FaceCheckService.Get.fetchTeamsOfOperator(operatorId, options).pipe(
          map(teams => timekeepingActions.setTeams(teams)),
          catchError(error => {
            Utils.errorHandling(error);
            return [timekeepingActions.setCheckInData(undefined), timekeepingActions.setTeams([])];
          }),
        ),
        [stopLoading({ key: 'getTeams' })],
      );
    }),
  );
};

const getTimeKeepingOfTeamRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.getTimeKeepingOfTeamRequest.match),
    switchMap(action => {
      const { team_id, working_day, accessToken } = action.payload;
      const options: RequestOptions = {};
      if (accessToken) {
        options.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return concat(
        [startLoading({ key: 'getTimeKeepingOfTeamRequest' })],
        FaceCheckService.Get.fetchTimeKeepingOfTeamV2({ team_id, working_day }, options).pipe(
          map(timekeepingActions.setCheckInData),
          catchError(error => {
            Utils.errorHandling(error);
            return [timekeepingActions.setCheckInData(undefined)];
          }),
        ),
        [stopLoading({ key: 'getTimeKeepingOfTeamRequest' })],
      );
    }),
  );
};

const approvedHoursWorkingRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.approvedHoursWorkingRequest.match),
    switchMap(action => {
      const { approvedData, accessToken } = action.payload;
      // eslint-disable-next-line
      const { working_day, team_id, face_Identity_Id, meal } = approvedData;
      // const mealInfo = { meal1: meal > 0 ? 1 : 0, meal2: meal === 2 ? 1 : 0 };
      // const mealData: CheckInMealPayload = {
      //   working_Day: working_day,
      //   mealList: [{ face_Identity_Id, information: JSON.stringify(mealInfo) }],
      // };
      const options: RequestOptions = {};
      if (accessToken) {
        options.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return concat(
        [startLoading({ key: 'approvedHoursWorkingRequest' })],
        // FaceCheckService.Post.checkInMeal(mealData, options).pipe(
        //   switchMap((mealResponse) => {
        //     // todo:
        //     console.log('Lưu cơm thành công: ', mealResponse);
        //     return [];
        //   }),
        //   catchError(error => {
        //     console.log('Lưu cơm thất bại: ', error);
        //     return [];
        //   }),
        // ),
        FaceCheckService.Post.approvedHoursWorking(approvedData, options).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [timekeepingActions.getTimeKeepingOfTeamRequest({ team_id, working_day, accessToken })];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'approvedHoursWorkingRequest' })],
      );
    }),
  );
};

const getCheckInPhoto$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.getCheckInPhoto.match),
    switchMap(action => {
      const { checkInId, accessToken } = action.payload;
      const options: RequestOptions = {};
      if (accessToken) {
        options.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return concat(
        [startLoading({ key: 'getCheckInPhoto' })],
        FaceCheckService.Get.fetchCheckInPhoto(checkInId, options).pipe(
          switchMap(blob => {
            const objectUrl = URL.createObjectURL(blob);
            return [timekeepingActions.setCheckInPhoto(objectUrl)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [timekeepingActions.setCheckInPhoto(undefined)];
          }),
        ),
        [stopLoading({ key: 'getCheckInPhoto' })],
      );
    }),
  );
};

const approveCheckInByDayRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(timekeepingActions.approveCheckInByDayRequest.match),
    switchMap(action => {
      const { input, callback } = action.payload;

      let res: any;
      return concat(
        [startLoading({ key: 'approveCheckIn' })],
        FaceCheckService.Post.approveAttendanceByDay(input).pipe(
          switchMap(result => {
            res = result;
            Utils.successNotification('Lưu thành công');
            return [
              hideModal({key: ApproveAttendanceModalName}),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        of(stopLoading({ key: 'approveCheckIn' })).pipe(
          finalize(() => {
            if (callback) {
              callback(res)
            }
          })
        ),
      );
    }),
  );
};

export const timekeepingEpics = [
  getTeamsOfOperatorRequest$,
  getTimeKeepingOfTeamRequest$,
  approvedHoursWorkingRequest$,
  getCheckInPhoto$,
  approveCheckInByDayRequest$,
];
